import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouterName } from "./RouterName";
import Loadable from "../components/loadable/loadable";

/* ***Layouts**** */

const PanoramaViewer = Loadable(
  lazy(() => import("../pages/PanoramaViewer/PanoramaViewer"))
);
const PanoramaViewerEditor = Loadable(
  lazy(() => import("../pages/PanoramaViewerEditor/PanoramaViewerEditor"))
);

const Router = [
  {
    path: "/",
    children: [
      { path: RouterName.home.home, element: <PanoramaViewer /> },
      {
        path: RouterName.home.book,
        element: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              background: "black",
            }}
          />
        ),
      },
      { path: RouterName.admin.home, element: <PanoramaViewerEditor /> },

      { path: "/404", element: <Navigate to={"/"} /> },
    ],
  },
];

export default Router;
