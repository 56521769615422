import Router from "./routes/router";
import React from "react";
import { useRoutes } from "react-router";

const App = () => {
  const routing = useRoutes(Router);
  return <div>{routing}</div>;
};

export default App;
