import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.id = action?.payload?.id;
    },
  },
});

export const { setUserInfo, setDataPrivileges, removeUserInfo } =
  UserSlice.actions;

export default UserSlice.reducer;
